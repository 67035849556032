import React, { useState } from 'react';
import { AlertNotification, Button, InputStepper, Loading } from '@commonsku/styles';

type AdminLicensesLicensesDetailsProps = {
  state: {activeUsers: number, quantity: number},
  onSave: (seats: number) => Promise<void>
};

export default function AdminLicensesLicensesDetails({
  state: { quantity, activeUsers },
  onSave
}: AdminLicensesLicensesDetailsProps) {
  const [seats, setSeats] = useState<number>(quantity);
  const [loading, setLoading] = useState(false);

  const handleOnClick = async (seats: number) => {
    setLoading(true);
    await onSave(seats);
    setLoading(false);
  };

  const content = loading ? <Loading mt={60} /> :
    <>
      <div style={{width: '320px', marginTop: '30px'}}>
        <span style={{fontWeight: 'bold'}}>Users:</span>
        <span style={{float: 'right'}}>{activeUsers}</span>
      </div>
      <div style={{width: '390px', fontWeight: 'bold', marginTop: '35px'}}>
        <span style={{fontWeight: 'bold', marginTop: '15px', display: 'inline-block'}}>Licenses:</span>
        <span style={{float: 'right'}}>
          <InputStepper
            initialValue={quantity}
            max={999}
            min={quantity}
            onChange={(value)=>{
              setSeats(parseInt(`${value}`))
            }}
          />
        </span>
      </div>
    </>;

  // update link below
  return (
    <>
      <AlertNotification href='https://help.commonsku.com/knowledge/add-and-remove-users' learnMore={true} target={'_blank'}>
        Additional licenses are committed through the end of the term
      </AlertNotification>
      {content}
      <Button disabled={loading || seats === quantity} style={{position: 'absolute', bottom: '20px', right: '20px'}} onClick={() => {
        handleOnClick(seats)
      }}>Save</Button>
    </>
  );
}
